<template>
<v-expansion-panels flat>
  <v-expansion-panel class="my-1" active-class="cont-shadown-rounded">
    <v-expansion-panel-header class="under-sec-flat py-0 px-2">
      <v-row>
        <v-col cols="8" class="font-normal text-primary-dark py-0 ma-0 ColumnaResumida">{{Titulo}}</v-col>
        <v-col v-if ="ConteoON" cols="4" class="text-primary-dark py-0 ma-0 ColumnaResumida">Respuestas {{NoRespuestas}}/{{NoPreguntas}}</v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content v-for="(Pregunta, key) in aPreguntas" :key="key">
      <Pregunta :oPregunta="Pregunta" @Respondio="ValidarNoRespuestas"></Pregunta>
    </v-expansion-panel-content>
  </v-expansion-panel>
</v-expansion-panels>
</template>

<script>
// toca llamar a este dentro de un  <v-expansion-panels > </v-expansion-panels>
import Pregunta from '@/components/Seif/Supervision/Preguntas.vue'

export default {
  name:"PanelExpansion",
  props: {
    ConteoON: {         // activa el conteo de preguntas llenas y existentes
      type: Boolean,
      default: false
    },
    Titulo: {
      type: String,
      default: ''
    },
    aPreguntas: {
      type: Array,
      default : () => []
    }
  },
  components: {
    Pregunta,
  },
  data() {
    return {
      NoRespuestas: 0
    }
  },
  mounted () {
    // quito todos las propiedades de respuestas del objeto aPreguntas.
    this.aPreguntas.forEach( pregunta => delete pregunta.respuesta)
  },
  methods: {
    ValidarNoRespuestas() {
      // valido en cuantos objetos exite 
      this.NoRespuestas = this.aPreguntas.filter( pregunta => (pregunta.hasOwnProperty('Respuesta') && pregunta.Respuesta != undefined)).length

    }
  },
  computed: {
    NoPreguntas() {
      return this.aPreguntas.length
    },

  },
}
</script>

<style lang="scss" scoped>

</style>