<template>
  <v-dialog v-model="value" persistent max-width="750px">
    <v-card class="pb-5">
      <v-toolbar
        color="headformsdark"
        height="45"
        elevation="0"
        dense
        dark>
        <v-spacer></v-spacer>
        <span class="mdlHeadForms">Nuevo Registro</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="btncolor-dark"
          @click="CloseDialog()"
          icon
          small
          dark
          outlined>
          <v-icon size="14px">fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        color=""
        height="45"
        elevation="0"
        dense
        class="under-sec-flat">
        <h3 class="text-medium font-normal margin-auto"> <span class="text-primary">Fecha de Ejecución: </span>  {{HoraRegistro}}</h3>
      </v-toolbar>  
        <v-container :key="KeyRefresh" class="mt-3 pa-3">
          <v-row >
            <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida" >
              <FormSelect
                v-model="Cliente"
                :titulo="'Cliente'"
                :inner="'Clientes,'"
                :campo="'Nombre_Cliente'"
                :obligatorio="true"
                :editable="'1'"
              ></FormSelect>
            </v-col>  
            <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
              <FormSelectFiltro
                v-model="Sede"
                :titulo="'Sede'"
                :inner="'sede,'"
                :campo="'Nombre'"
                :obligatorio="true"
                :campoFiltro="'idCliente'"
                :valorFiltro="Cliente"
                :editable="'1'"
              ></FormSelectFiltro>
            </v-col>  
            <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
              <FormSelectFiltro
                v-model="Puesto"
                :titulo="'Puesto'"
                :inner="'Puestos,'"
                :campo="'NombrePuesto'"
                :obligatorio="true"
                :campoFiltro="'idsede'"
                :valorFiltro="Sede"
                :editable="'1'"
              ></FormSelectFiltro>
            </v-col> 
            <v-col sm="12" cols="12" class="py-4 px-5 mb-2 ColumnaResumida under-sec-flat">
              <span class="titleslight--text">Es posible establecer comunicación con el usuario ? * </span>
              <v-radio-group v-model="seContacto" row hide-details >
                <v-radio label="SI" value="1"></v-radio>
                <v-radio label="NO" value="0"></v-radio>
              </v-radio-group>
            </v-col>  
            <template v-if="seContacto == '1'">
              <v-col sm="6" cols="6" class="py-0 ma-0 ColumnaResumida">
                <FormTexto
                  v-model="Funcionario"
                  :titulo="'Funcionario'"
                  :editable="'1'"
                  :obligatorio="true">
                </FormTexto>
              </v-col>  
              <v-col sm="6" cols="6" class="py-0 ma-0 ColumnaResumida">
                <FormTexto
                  v-model="Cedula"
                  :titulo="'Cédula'"
                  :editable="'1'"
                  :obligatorio="true">
                </FormTexto>
              </v-col>  
              <v-col sm="6" cols="6" class="py-0 ma-0 ColumnaResumida">
                <FormSelect
                  v-model="Motivo"
                  :titulo="'Motivo Supervisión Virtual'"
                  :inner="'COPSECANCOL_Supervisiones_Motivos,'"
                  :campo="'Motivo'"
                  :obligatorio="true"
                  :editable="'1'"
                ></FormSelect>
              </v-col>  
              <v-col sm="6" cols="6" class="py-0 ma-0 ColumnaResumida">
                <FormSelect
                  v-model="Medio"
                  :titulo="'Medio de la Supervisión Virtual'"
                  :inner="'COPSECANCOL_Supervisiones_Medios,'"
                  :campo="'Medio'"
                  :obligatorio="true"
                  :editable="'1'"
                ></FormSelect>
              </v-col>  
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                <v-expansion-panels v-for="(Panel, key) in aExpansionPaneles_info" :key="key">
                  <PanelExpansion v-if="Panel.aData.length > 0" :Titulo="Panel.Label" :ConteoON="true" :aPreguntas="Panel.aData"></PanelExpansion>
                </v-expansion-panels>
              </v-col>  
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                <FormTexto
                  v-model="Observaciones"
                  :titulo="'Observaciones Generales'"
                  :editable="'1'"
                  :obligatorio="true">
                </FormTexto>
              </v-col>  
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                 <span class="titleslight--text"> Anexos </span>
                 <DragUpdateFile @files="f_anexos" :NoAnexos="10" extenAccept="image/*"></DragUpdateFile>
              </v-col>    
            </template>  
            <template v-else>
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                <FormTexto
                  v-model="Observaciones"
                  :titulo="'Observaciones Generales'"
                  :editable="'1'"
                  :obligatorio="true">
                </FormTexto>
              </v-col>  
            </template>
            <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
              <v-row justify="center" align="center" class="pa-1">
                <v-btn class="no-uppercase btn-rounded d-flex mx-2" large depressed dark color="btndest" @click="Save" :loading="bLoading">Guardar</v-btn>
                <v-btn class="no-uppercase btn-rounded d-flex mx-2" large depressed dark color="btndest" @click="CloseDialog">Cancelar</v-btn>
              </v-row>  
            </v-col>
          </v-row>
        </v-container>    
    </v-card>
    <ErrorView v-model="ShowErrores" :aErrores="aErrores" :sAncho="'650px'"></ErrorView>
  </v-dialog>    
</template>

<script>
import mxFunciones from '@/mixins/funciones.js'
import HoraFecha from '@/Utlidades/Hora_Fecha.js'
import FormSelect from '@/components/Form/Componentes/FormSelect.vue'
import FormSelectFiltro from '@/components/Form/Componentes/FormSelectFiltro.vue'
import FormTexto from '@/components/Form/Componentes/FormTexto.vue'
import PanelExpansion from '@/components/Seif/Supervision/PanelExpansion.vue'
import axiosServices from '@/services/axiosServices.js'
import DragUpdateFile from '@/components/DragUpdateFile.vue'
import GPSLocation from '@/Utlidades/GPSlocation.js'
import ErrorView from '@/components/ErrorView.vue'


export default {
  name:"NewSupervision",
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  components: {
    FormSelect,
    FormSelectFiltro,
    FormTexto,
    PanelExpansion,
    DragUpdateFile,
    ErrorView,
  },
  data() {
    return {
      Cliente : '',
      Sede    : '',
      Puesto  : '',
      Cedula  : '',
      Motivo  : '',
      Medio   : '',
      Funcionario   : '',
      Observaciones : '',
      KeyRefresh : 0,   // empleo esta variale para refrescar los componentes
      seContacto : '0',
      aExpansionPaneles_info : [],
      showNewPQR  : false,
      ShowErrores : false,
      aAnexos  : [],
      aErrores : [],
      bLoading : false
    }
  },
  mixins: [mxFunciones],
  async mounted (){
    this.aExpansionPaneles_info = await axiosServices.getConfiguration('/cop/preguntas/3',true )
    this.aExpansionPaneles_info = this.aExpansionPaneles_info.aData
  },
  methods: {
    CloseDialog() {
      this.$emit('input', false)
      this.$emit('saveOk')
    },
    refrescar(){
      this.KeyRefresh++
    },
    async Save(){
      
      try {

        this.validarCampos()

        // Valido que ShowErrores siga en falso
        if(this.ShowErrores) return
          
        const oLocation = await GPSLocation.getCurrentPosition()

        const oBody = {
          app      : 'ActivitySupervisionSecancol',
          accion   : "Supervision_Secancol",
          Fecha    : `${this.HoraRegistro}`,
          Latitude : oLocation.coords.latitude,
          Longitud : oLocation.coords.longitude,
          Cliente  : this.Cliente,
          Sede     : this.Sede,
          Puesto   : this.Puesto,
          seContacto     : this.seContacto,
          NombreGuarda   : this.Funcionario,
          CedulaGuarda   : this.Cedula,
          Calificaciones : this.seContacto == '1' ? this.aExpansionPaneles_info.Calificacion.aData : [],
          Elementos      : this.seContacto == '1' ? this.aExpansionPaneles_info.Elementos.aData : [],
          Instalaciones  : this.seContacto == '1' ? this.aExpansionPaneles_info.Locativas.aData : [],
          Observaciones  : this.Observaciones,
          Evaluacion     : "",
          firmaOperaciones : "sinfoto.jpg",
          firmaGuarda      : "sinfoto.jpg",
          firmaSupervisor  : "sinfoto.jpg",
          Galeria          : this.FormatAnexos(), 
          motivo           : this.Motivo,
          medio            : this.Medio,
          isVirtual        : "1", 

        }

        this.bLoading = true
        const respuesta = await axiosServices.postConfiguration('/cop/supervision-inspeccion', oBody, true)
        

        if(respuesta.sStatus == "success"){
          // Almaceno las imagenes
          this.subirAnexos()

          this.$store.dispatch('notificaciones/SetMensaje', `Registro alamacenado Satisfactoriamente No:${respuesta.aData}` )
          this.$store.dispatch('notificaciones/SetColor','success')
          this.$store.dispatch('notificaciones/ShowNotificacion',true)

          this.CloseDialog()

        } else {
          this.$store.dispatch('notificaciones/SetMensaje', `Se presenta problemas almacenando Registro.` )
          this.$store.dispatch('notificaciones/SetColor','error')
           this.$store.dispatch('notificaciones/ShowNotificacion',true)
        }



      } catch (error) {
          this.$store.dispatch('notificaciones/SetMensaje','ERROR: GPS inactivo o No se tienen permiso para obtener la Ubicabion. Valide y refresque la Pantalla. Sus datos no se almacenaron' )
          this.$store.dispatch('notificaciones/SetColor','error')
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
          this.bErrorPermisos = false
      }

      
    },
    f_anexos(files){
      this.aAnexos = files
    },
    FormatAnexos() {
      let aGaleria = [];
      // recorro los Anexos para sacar todos los nombres de las anexos que tenga y dejarlos como [ {Foto : "NombreArchico"}]
      this.aAnexos.forEach(Anexos => {
        // valido que no sea un file lizt con mas de un elemento 
        if(Anexos.length > 1){
          // Lo recorro de nuevo
          Anexos.forEach( Anexo => {
            aGaleria.push({ Foto : Anexo.name})
          })
        } 
        if(Anexos.length == 1) {
          aGaleria.push({ Foto : Anexos[0].name})
        }
      })

      return aGaleria

    },
    validarCampos(){
      this.aErrores = []
      this.ShowErrores = false

      if(this.Cliente === '' || this.Cliente === null) this.aErrores.push('Debe seleccionar un cliente')
      if(this.Sede === '' || this.Sede === null) this.aErrores.push('Debe seleccionar una sede')
      if(this.Puesto === '' || this.Puesto === null) this.aErrores.push('Debe seleccionar un puesto')
      if(this.Observaciones === '' || this.Observaciones === null) this.aErrores.push('Falta diligenciar las Observaciones Generales')

      if(this.seContacto === '1'){
        if(this.Funcionario === '' || this.Funcionario === null) this.aErrores.push('Debe indicar un funcionario')
        if(this.Cedula === '' || this.Cedula === null) this.aErrores.push('Debe indicar la cedula del funcinario')
        if(this.Cedula.match(/^0/))  this.aErrores.push("La Cedula no puede iniciar por el número '0'")
        if(this.Motivo === '' || this.Motivo === null) this.aErrores.push('Debe indicar un Motivo')
        if(this.Medio === '' || this.Medio === null) this.aErrores.push('Debe indicar un Medio')
        this.validarExpansion()
      }

      this.ShowErrores = this.aErrores.length > 0

    },
    validarExpansion() {
      // recorro el arreglo validando si todos los campos tienen preguntas y si respuestas esta en 0 valido que observacion y archivo sea obligatorio

      for(const propiedad in this.aExpansionPaneles_info)  {
        const NoPregunta = this.aExpansionPaneles_info[propiedad].aData.length
        const NoRespuestas = this.aExpansionPaneles_info[propiedad].aData.filter( Pregunta => (Pregunta.hasOwnProperty('Respuesta') && Pregunta.Respuesta != undefined)).length
        const RespFaltantes = NoPregunta - NoRespuestas

        if(RespFaltantes > 0 ) this.aErrores.push(`En ${this.aExpansionPaneles_info[propiedad].Label} tiene ${RespFaltantes} preguntas sin contestar`)
        
        // const FaltaAnexoObserva = this.aExpansionPaneles_info[propiedad].aData.filter( Pregunta => (Pregunta.hasOwnProperty('Respuesta') && Pregunta.Respuesta == 0 && 
        //                                                                                               (Pregunta.hasOwnProperty('Observaciones') && Pregunta.Observaciones === '' || Pregunta.hasOwnProperty('aFile') && Pregunta.aFile.length ==  0)
        //                                                                                             )
        //                                                                               ).length
        // if(FaltaAnexoObserva) this.aErrores.push(`En ${this.aExpansionPaneles_info[propiedad].Label} tiene  ${FaltaAnexoObserva} preguntas cuya respuestas requiere Observaciones y/o Anexo`)

        const FaltaObserva = this.aExpansionPaneles_info[propiedad].aData.filter( Pregunta => (Pregunta.hasOwnProperty('Respuesta') && Pregunta.Respuesta == 0 && 
                                                                                                      (Pregunta.hasOwnProperty('Observaciones') && (Pregunta.Observaciones === '' || Pregunta.Observaciones === null)))).length

        if(FaltaObserva) this.aErrores.push(`En ${this.aExpansionPaneles_info[propiedad].Label} tiene ${FaltaObserva} preguntas cuya respuestas requiere una Observacion`)
        
        const FaltaAnexo = this.aExpansionPaneles_info[propiedad].aData.filter( Pregunta => (Pregunta.hasOwnProperty('Respuesta') && Pregunta.Respuesta == 0 && (Pregunta.hasOwnProperty('aFile') && Pregunta.aFile.length ==  0))).length

        if(FaltaAnexo) this.aErrores.push(`En ${this.aExpansionPaneles_info[propiedad].Label} tiene ${FaltaAnexo} preguntas cuya respuestas requiere un Anexo`)


      }
    },
    subirAnexos() {
      // primero valido si exitio comunicacion si NO me regreso de una
      if(this.seContacto == '0') return  

      // recorreo el arreglo de de expansion para ir enviando los Anexos, no lo recorreo en conjunto con ValidarExpansion para no tener que crear un array que se cree y destulla mucas veces
      for(const propiedad in this.aExpansionPaneles_info)  {
        this.aExpansionPaneles_info[propiedad].aData.forEach( Pregunta => { 
          if( Pregunta.Respuesta == 0) {
              // envio File de una por que a esta altura ya he validado 
              this.enviarFile(Pregunta.aFile[0])
          }
        })
        
      }
      
      //Recorro los anexos para enviar ya se que esta lleno por que es obligatorio
      this.aAnexos.forEach(Anexos => {
        // valido que no sea un file list con mas de un elemento 
        if(Anexos.length > 1){
          // Lo recorro de nuevo
          Anexos.forEach( Anexo => {
              this.enviarFile(Anexo)
          })
        }  
        if(Anexos.length == 1){
          this.enviarFile(Anexos[0])
        }
      })




    },
    enviarFile(fFile){
      const formData = new FormData()
      formData.append('file', fFile)
      axiosServices.postFile('/subirarchivo', formData, true)
    }

  },
  computed: {
    HoraRegistro() {
      this.refrescar()
      if(this.value){
        return HoraFecha.getNow()
      }

      return ''
    },
  },
  watch: {
    Cliente(newValue){
      if(newValue === null){
        this.Sede = null
        this.Puesto = null
      }
    },
    Sede(newValue) {
      if(newValue === null) this.Puesto = null
    }

  },

}
</script>

<style lang="scss" scoped>

</style>
