<template>
  <ContenedorPreguntas :Pregunta="oPregunta.TextPregunta">
    <v-btn-toggle
      v-model="Respuesta"
      @change="Respondio"
      active-class="btn-activo"
    
     >
      <v-btn 
        width="120"
        height="35"
        rounded
        v-for="(info, key) in aOptions" 
        :key="key"  
        :value="info.ID">
        {{info.DatoMostrar}}
      </v-btn>
    </v-btn-toggle>
    <!-- si se selecciona regular o no -->
    <v-container v-if="Respuesta === 0" class="my-5">
      <FormCheckBox v-if="oPregunta.PermitirConvertirPQR == '1'" 
        titulo="Convertir a PQR"
        v-model="ConvertirPQR">
      </FormCheckBox>
      <FormTexto
        v-model="Observaciones"
        :titulo="'Observaciones'"
        :editable="'1'"
        :obligatorio="true">
      </FormTexto>
      <span class="titleslight--text">Anexo: <span class="black--text">{{this.oPregunta.Foto === 'sinfoto.jpg' ? '' : this.oPregunta.Foto}}</span> * </span>
      <!-- lo refresco cada vez que lo utilizo  por eso el componente nunca muestra el nombre de archivo y lo pongo a mano entonce -->
      <DragUpdateFile @files="getanexo" :NoAnexos="1" :key='Keyrefres' extenAccept="image/*"></DragUpdateFile>
    </v-container>
  </ContenedorPreguntas>
</template>

<script>
import ContenedorPreguntas from '@/components/Seif/Supervision/ContenedorPreguntas.vue'
import FormTexto from '@/components/Form/Componentes/FormTexto.vue'
import FormCheckBox from '@/components/Form/Componentes/FormCheckBox.vue'
import DragUpdateFile from '@/components/DragUpdateFile.vue'


export default {
  name:"Preguntas",
  props: {
    oPregunta: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    ContenedorPreguntas,
    FormTexto,
    FormCheckBox,
    DragUpdateFile,
  },
  data() {
    return {
      Respuesta : '', 
      Observaciones : '',
      anexo : [],
      Keyrefres : 0,
      ConvertirPQR : false,
    }
  },
  methods: {
    Respondio () {
      // Como el valor que se tienen en props oPregunta es una referencia por lo cual si cambio aqui me cambi el valor en PanelExpansion
      // pero emito para que se sepa que se cambio algo por que el cambio de la propiedad del objeto solo no se puede detectar. 
      this.oPregunta.Respuesta = this.Respuesta
      this.oPregunta.Observaciones = this.Observaciones
      this.oPregunta.Foto = this.anexo.length  > 0 ? this.anexo[0].name : 'sinfoto.jpg'
      this.oPregunta.aFile = this.anexo.length  > 0 ? this.anexo : []
      this.oPregunta.ConvertirPqr = this.ConvertirPQR ? 1 : 0
      this.$emit('Respondio')
      this.Keyrefres++   // refresco el componente 
    },
    getanexo(files){
      this.anexo = files[0]
      this.Respondio()
    }
  },
  computed: {
    aOptions() {
      if(this.oPregunta.detalle == '0'){
        return  [
          { ID: 0 , DatoMostrar: 'REGULAR' },
          { ID: 1 , DatoMostrar: 'BUENO' },
          { ID: 2 , DatoMostrar: 'N/A' },
        ]
      }else{ 
        return  [
          { ID: 0 , DatoMostrar: 'NO' },
          { ID: 1 , DatoMostrar: 'SI' },
        ]

      }
    }
  },
  watch: {
    Observaciones() {
      this.oPregunta.Observaciones = this.Observaciones
      this.Respondio()
    },
  },
  
}
</script>

<style lang="scss" scoped>
.btn-activo{
  background: var(--v-primary-base)!important;
  color: white!important;
}
</style>
