<template>
  <v-container grid-list-xs class="pa-0 ma-0" fluid>
    <v-row class="pa-0 ma-0 titlesdark--text" >
      <v-col md="8" sm="12" class="pa-0 ma-0 mt-1">
        <span class="pl-1 text-light font-size-2">{{tituloGrids}}</span>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col md="12" sm="12" class="pa-0 ma-0 pt-2">
        <Grid
          v-if="this.$store.state.token!=''"
          :key="keyReload"
          :tabla="TablaName"
          :Detalles="'DetalleSupervision'"
          :bActiveFilter="true"
          :bActiveExport="true"
          :bSingleSelect="true"
          :bShowSelect="true"
          :sIdTable="'idSupervision'"
          :bShowMapDetails="false"
          :bActiveNew="false"
          :aInfoTBDetalleExp="aDetallesExport"
          @saveOk="createDetaill"
          @idSelect="getIdSelect"
          @nameField="getNameFieldSelect">
            <template v-slot:slotbtnNew>
                
                <v-btn v-if="oPermisos.priv_insert=='1'" class="no-uppercase btn-rounded d-flex mx-2 my-1" small depressed dark  color="btndest" @click="showModal">Nuevo Registro</v-btn>
                <NewSupervision 
                  v-model="showNewSupervision" 
                  @saveOk="ActualizarGrid"
                  :key="keyreset">
                </NewSupervision>

            </template>
        </Grid>  
      </v-col>
      <!-- GRID Detalle -->
      <v-col md="12" sm="12" class="pa-0 ma-0 mt-2">
        <v-toolbar class="back-dark rounded-top" dark height="40">
          <v-toolbar-title class="mt-3">Detalles Calificación Preguntas</v-toolbar-title>
          <template v-slot:extension>
            <v-tabs v-model="nTabSelect" align-with-title dark active-class="active-item">
              <v-tabs-slider color="btndest lighten-3"></v-tabs-slider>
              <v-tab v-for="nameTab in aInfoTab" :key="nameTab" dark>
                {{nameTab}}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>  
        <v-tabs-items v-model="nTabSelect">
          <v-tab-item v-for="(item, key) in aTablasDetalles" :key="key">
            <GridDetalle
              v-if="idSelect != 0"
              :key="key"
              :bGridDetalle="true"
              :tabla="item"
              :sIdTable="'idRegistroRespuesta'"
              :sCampoFiltro="'idSupervision'"
              :nIdValor="idSelect"
              :bActiveFilter="false"
              :bActiveExport="false"
              :bSingleSelect="true"
              :bShowSelect="false"
              :bShowMapDetails="false"
              :bActiveNew="false"
              @nameField="getNameFieldSelect">
            </GridDetalle>
          </v-tab-item>
        </v-tabs-items>  
      </v-col>  
    </v-row>
  </v-container>
</template>

<script>
import Grid from '@/components/Grid/Grid.vue'
import GridDetalle from '@/components/Grid/Grid.vue'
import mxFunciones from '@/mixins/funciones.js'
import NewSupervision from '@/components/Seif/Supervision/NewSupervision.vue'

export default {
  name: 'Supervision',
  components: {
    Grid,
    GridDetalle,
    NewSupervision
  },
  mixins: [mxFunciones],
  data() {
    return {
      TablaName : 'COPSECANCOL_Supervisiones',    // Nombre de la tabla a trabajar
      aTablasDetalles : [
          'COPSECANCOL_Supervision_Calificaciones',
          'COPSECANCOL_Supervision_Elementos',
          'COPSECANCOL_Supervision_Elementos$1'
      ],
      idSelect  : 0,                 // Id seleccionado en la tabla principal inicia en 0
      nameField : '',                 // Nombre del campo foreignKey
      showNewSupervision : false,
      keyreset  : 0,
      keyReload : 0,
      nTabSelect : null,
      aInfoTab  : [] ,
      aDetallesExport : [
        { 
          sTablaDetalle: 'COPSECANCOL_Supervision_Calificaciones',
          sNombreCampoFiltroDetalle: 'idSupervision',
          sCampoParaFiltroTBPrincipal: 'idSupervision',
        },
        { 
          sTablaDetalle: 'COPSECANCOL_Supervision_Elementos',
          sNombreCampoFiltroDetalle: 'idSupervision',
          sCampoParaFiltroTBPrincipal: 'idSupervision',
        },
        { 
          sTablaDetalle: 'COPSECANCOL_Supervision_Elementos$1',
          sNombreCampoFiltroDetalle: 'idSupervision',
          sCampoParaFiltroTBPrincipal: 'idSupervision',
        },

      ],
    }
  },
  async mounted() {
    this.onValidarTokenSinLogin()
    this.tituloGrids = await this.getTitulo(this.TablaName)
    this.aTablasDetalles.forEach(async TablaDetalle => {
      this.aInfoTab.push(await this.getTitulo(TablaDetalle))
    })
  },
  methods: {
    getIdSelect(sIdSelected) {
      this.idSelect = sIdSelected;
    },
    getNameFieldSelect(sNameFiled) {
      this.nameField = sNameFiled;
    },
    createDetaill() {

    },
    ActualizarGrid(){
      this.keyReload++
    },
    showModal() {
      this.showNewSupervision = true
      this.keyreset++
    }

  },
}
</script>

<style lang="scss" scoped>
.active-item{
  color: var(--v-btndest-base);
}
</style>